import { useEffect, useState } from "react";
import { useCountdown } from "src/hooks/use-count-down";


export interface UserLockUserDialogProps {
    children?: JSX.Element;
    lockoutSeconds: number;
}
export const useUserLockUserDialogState = (props: UserLockUserDialogProps) => {

    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        resetCountdown();
        startCountdown();
        setOpen(props.lockoutSeconds > 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.lockoutSeconds]);

    const closeDialog = () => {
        setOpen(false);
    };

    const [count, { startCountdown, resetCountdown }] = useCountdown({
        countStart: props.lockoutSeconds,
        intervalMs: 1000,
    })

    useEffect(() => {
        if (count === 0) {
            setOpen(false);
        }
    }, [count, setOpen]);

    useEffect(() => {
        startCountdown()
    }, [startCountdown])

    return {
        isOpen,
        count,
        closeDialog,
    }
}